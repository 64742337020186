<template>
  <div>
    <!-- <div class="about-hero">
      <div class="about-hero__inner">
        <h1 class="about-hero__header">मेल्लेख गाउँपालिका</h1>
        <div class="about-hero__province">
          <span class="about-hero__"></span>
          <span>अछाम, प्रदेश नं ७</span>
          <span></span>
        </div>
        <div class="about-hero__info">
          <div class="about-hero-info">
            <div class="about-hero-info__name">जनसंख्या</div>
            <div class="about-hero-info__value">
              {{ englishToNepaliNumber(aboutInfo.population) || "n/a" }}
            </div>
          </div>
          <div class="about-hero-info">
            <div class="about-hero-info__name">वडाहरु</div>
            <div class="about-hero-info__value">
              {{ englishToNepaliNumber(aboutInfo.wards) || "n/a" }}
            </div>
          </div>
          <div class="about-hero-info">
            <div class="about-hero-info__name">परिवारहरु</div>
            <div class="about-hero-info__value">
              {{ englishToNepaliNumber(aboutInfo.families) || "n/a" }}
            </div>
          </div>
          <div class="about-hero-info">
            <div class="about-hero-info__name">क्षेत्रफल (वर्ग किमी)</div>
            <div class="about-hero-info__value">
              {{ englishToNepaliNumber(aboutInfo.area) || "n/a" }}
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="about-wrapper">
      <div class="about-tab">
        <div class="about-tab--inner">
          <ul class="about-tab__list">
            <router-link
              class="about-tab__item"
              tag="li"
              active-class="about-tab__item--active"
              :class="{ 'about-tab__item--active': checkAchievementLinkActive }"
              :to="{ name: 'achievements' }"
              exact
              >महत्वपुर्ण कार्यक्रम</router-link
            >
            <router-link
              class="about-tab__item"
              tag="li"
              active-class="about-tab__item--active"
              :class="{ 'about-tab__item--active': checkUpcomingLinkActive }"
              :to="{ name: 'upcoming-programs' }"
            >
              आगामी कार्यक्रम
            </router-link>
            <router-link
              class="about-tab__item"
              tag="li"
              active-class="about-tab__item--active"
              :to="{ name: 'introduction' }"
            >
              परिचय
            </router-link>
            <router-link
              class="about-tab__item"
              tag="li"
              active-class="about-tab__item--active"
              :to="{ name: 'history' }"
            >
              इतिहास
            </router-link>
            <router-link
              class="about-tab__item"
              tag="li"
              active-class="about-tab__item--active"
              :to="{ name: 'naming' }"
            >
              नामकरण
            </router-link>
          </ul>
        </div>
      </div>
      <div class="about-tab-content">
        <!-- <vue-custom-scrollbar class="scroll-area" :key="key"> -->
        <router-view></router-view>
        <!-- </vue-custom-scrollbar> -->
      </div>
      <!-- <vue-custom-scrollbar class="scroll-area" :key="key">
        <div class="about-section">
          <div v-if="isActive('achievements')">
            <div
              v-for="achievement in achievements"
              :key="achievement['s.no.']"
              style="border-bottom: 1px solid white"
            >
              <dl>
                <dt>Title</dt>
                <dd>{{ achievement.title }}</dd>
              </dl>
              <dl>
                <dt>challenges</dt>
                <dd>{{ achievement.challenges }}</dd>
              </dl>
              <dl>
                <dt>description</dt>
                <dd>{{ achievement.description }}</dd>
              </dl>
              <dl>
                <dt>startyear</dt>
                <dd>{{ achievement.startyear }}</dd>
              </dl>
              <dl>
                <dt>endyear</dt>
                <dd>{{ achievement.endyear }}</dd>
              </dl>
              <dl>
                <dt>expenditure</dt>
                <dd>{{ achievement.expenditure }}</dd>
              </dl>
              <dl>
                <dt>image before</dt>
                <dd>
                  <img
                    :src="achievement.imageid1before"
                    alt=""
                    style="height: 150px; width: auto; margin-right: 20px;"
                  />
                  <img
                    :src="achievement.imageid2before"
                    alt=""
                    style="height: 150px; width: auto"
                  />
                </dd>
              </dl>
              <dl>
                <dt>image after</dt>
                <dd>
                  <img
                    :src="achievement.imageid3after"
                    alt=""
                    style="height: 150px; width: auto; margin-right: 20px;"
                  /><img
                    :src="achievement.imageid3after"
                    alt=""
                    style="height: 150px; width: auto"
                  />
                </dd>
              </dl>
            </div>
          </div>
          <div v-if="isActive('upcomingPrograms')">
            <div
              v-for="program in upcomingPrograms"
              :key="program['s.no.']"
              style="border-bottom: 1px solid white"
            >
              <dl>
                <dt>Title</dt>
                <dd>{{ program.title }}</dd>
              </dl>    
              <dl>
                <dt>Description</dt>
                <dd>{{ program.description }}</dd>
              </dl>
              <dl>
                <dt>Start year</dt>
                <dd>{{ program.startyear }}</dd>
              </dl>
              <dl>
                <dt>End year</dt>
                <dd>{{ program.endyear }}</dd>
              </dl>
              <dl>
                <dt>Estimated Budget</dt>
                <dd>{{ program.estimatebudget }}</dd>
              </dl>          
            </div>
          </div>
          <div v-if="isActive('introduction')">
            <h2>मेल्लेखको परिचय</h2>
            <p>
              मेल्लेख गाउँपालिका ऐतिहाँसिक दृष्टिकोणले हालको मेल्लेख गाउँपालिका
              तथा साविकको विन्धेवासिनी गाविसको वडाको आफ्नै खालको विशेषता रहेको छ
              । मेल्लेख गाँउपालिकाका विभिन्न ८ वटा वडाहरुको अस्थायि केन्द्र पनि
              हो । यस विगतः जिल्लाको सदरमुकाम मंगलसेनवाट ९ कोष टाढा उत्तरमा
              अवस्थित छ । उक्त केन्द्र रहेको वडाका बारेमा विस्तृत हालको ५ नं.
              वडा विवरणमा उल्लेख छ । यस गाउँपालिकामा जम्मा ८ वडा रहेका छन् । यि
              ८ वटा वडाहरुको वर्तमानमा एकताको आधार विगतको पन्ध्र बीसलाई लिने
              गरिन्छ । यसको पूर्वमा रामाराशोन गाँउपालिका, पश्चिममा, साँफेवगर
              नगरपालिका, उत्तरमा बाजुरा जिल्ला र दक्षिणमा बान्नीगढी जयगढ
              गाउँपालिका यसका भौगोलिक सिमानाहरु हुन । हाल मेल्लेख गाउँपालिकाको
              कार्यालय रहेको स्थान समुन्द्री सतहदेखि १६९८ मिटरको उचाईमा रहेको छ
              । नेपालको एकिकरण अघि शाहवंशीय शासकहरु मध्येका बिम्कोटे राजाको शासन
              व्यवस्था भित्र रहेको थियो । विम्कोटको केही अंश पनि यसै गाविसमा
              पर्दछ । राणा कालमा सम्पन्न जनगणना १९६८ साल पश्चात अछाम जिल्लालाई
              विभिन्न दराहरुमा विभाजन गर्ने क्रममा हालको षोडशा, ठाँटी, कुस्कोट,
              बिन्धेवासिनी, हात्तीकोट, नन्देगडा र नवाठाना क्षेत्रलाई पन्ध्र बीस
              दरामा विभाजन गरीएको थियो । अछाम जिल्ला १३ वटा ईलाकाहरुमा विभाजित
              थियो । हालको मेल्लेख यि सबै गाउँपञ्चायतको मध्य भागमा रहेको छ ।
              दैनिक प्रशासनिक कामहरु नभएता पनि कुरा गर्ने, भेटघाट गर्ने थलोको
              रुपमा विकास हुदै केन्द्र भागमा रहेको हुदा तत्कालिन समयमा ईलाका
              सदरमुकामका रुपमा विकास गरिएको मानिन्छ ।
            </p>
            <a href="#" class="see-more">
              थप जानकारी
              <unicon name="sign-out-alt"></unicon>
            </a>
          </div>
          <div v-if="isActive('history')">
            <h2>मेल्लेखको इतिहास</h2>
            <p>
              २०४६ को जनआन्दोलन पश्चात्त सम्पन्न २०४९ को स्थानीय निर्वाचनमा यस
              ईलाकाबाट कांग्रेसको सहयोगमा हालको वार्ड नं. ५ निवासी मोति अधिकारी
              ईलाका सदस्यमा निर्वाचित भएका थिए भने २०५४ सालमा सम्पन्न स्थानीय
              निर्वाचनमा यस ईलाकाबाट तत्कालिन नेकपा (एमाले) उम्मेदवार वार्ड नं.
              ३ निवासी हेमराज शाह ईलाका सदस्य तथा वार्ड नं. ६ निवासी कृष्णप्रसाद
              जैशी जिविसको सभापतिमा निर्वाचित भएका थिए । पुरानो राजावादी सोच,
              समन्तवादका विरुद्धको लडाँई ,दमन, थिचोमिचो, निरंकुशता, तथा खोले
              टाकुरे सामन्तहरुको पञ्जाबाट मुक्त हुदै यस ईलाकामा हालको हात्तिकोट
              बाहेक सबै वडाहरुमा (विगतका गाविसहरुमा) तत्कालिन एमाले उम्मेदवारहरु
              विजयी हुदा जनतामा निकै उत्साह छाएको थियो । यहाँको जनजिवन र विकास
              पनि राष्ट्रिय राजनीतिसंग सम्बन्धित भएको हुदा मुलुकको तत्कालिन
              परिवेशलाई समेत ध्यान दिईनु पर्दछ । २०५२ साल फाल्गुण २ गतेबाट
              तत्कालिन माओवादीले राज्य विरुद्धको शसश्त्र संघर्ष सुरु ग¥यो । खास
              गरी २०५४ मा निर्वाचित स्थानीय सरकारको म्याद संविधानतः २०५९ मा
              समापन भएतापनि विषम राजनीतिक परिस्थितिमा चुनाव हुन नसक्ने अवस्थाको
              लेखाजोखा गरी राजनीतिक सहमतिका आधारमा १ वर्ष म्याद थप्ने तत्कालिन
              एमाले र कांग्रेसको प्रस्ताव तत्कालिन प्रधानमन्त्री देउवाद्धारा
              अस्विकार गरियो जसले राजनीति अवस्था थप तरल र अन्यौलता तर्फ धकेलियो
              । यस विच राज्य र माओवादी पक्षबाट थुप्रै जनधनको क्षेती भयो । यस
              ईलाका तथा हालको मेल्लेख गाउँपालिकाका वार्ड नं. ८ बाहेक सबै
              वडाहरुमा तत्कालिन जनयुद्धको प्रयाप्त प्रभाव रहयो । राजनीतिक
              हिसाबले गाउँमा कम्युनिष्ट बाहेक मान्छे भेटिदैन थे । कोहि
              विस्थापितका नाममा गाउँ छोड्न पुगे, कोहि केहि वा अन्य हिसाबले गाउँ
              छोडेको भेटिन्छ । उक्त क्रम १० वर्ष चल्यो । उक्त संघर्षको मैदानमा
              हतियार सहित अहिलेका गाउँपालिका अध्यक्ष लोकबहादुर बोहरा सहितका
              युवाहरुको सशक्त भूमिका छ । मुलुक स्थानीय सरकार विहिन भयो । विकास
              निर्माण र नीति निर्माण लथालिङ्ग वन्यो । जिल्ला सदरमुकाम समेत २०५८
              मा ध्वस्त भयो । गाउँबाट सदरमुकाम आवत जावत दोहोरो अनुमतिमा हुन
              थाल्यो । यस ईलाका अप्ठेरो परिस्थतिमा जिल्ला सदरमुकाम मंगलसेनमा
              यहाँका जनताको सुखदुःमा आवश्यक सहयोगका लागि शेरबहादुर कुवँर
              (राष्ट्रिय सभा सदस्य तथा एमाले अध्यक्ष) को भूमिका ठूलो छ भने यसै
              गाउँपालिका वार्ड नं. ६ निवासी हिरण्ड भट्टराई (नेकपा– गापा संयोजक
              तथा सुपखेविस कोषाध्यक्ष) पनि यस अभियानमै सहयोगी थिए जुन यस
              क्षेत्रका निम्ति हुने हरेका गतिविधिमा समाबेश हुने गर्दथे । यो क्रम
              २०६२÷०६३ सम्म चल्यो । तत्कालिन राजा ज्ञानेन्द्र निरंकुशता
              विरुद्धको आन्दोलनमा सातवटा राजनीति दल र माओबादी वीच राजनीतिक
              एजेण्डामा सहमति गरि गणतन्त्र स्थापनार्थ २०६२ मंशिर ७ गते बा¥हबुंदे
              समझदारी बन्यो । अब राजा एका तिर भए भने राजनीतिक दल अर्को तर्फ ।
              नेपालको राजनीतिक दुई ध्रुवमा केन्द्रित भयो । राष्ट्रिय–
              अन्तरराष्ट्रिय मान्यता प्राप्त आन्दोलन (२०६२ चैत्र २२ देखि २०६३
              बैशाष ११ गते) १९ दिनसम्म चलेको थियो । आन्दोलनमा २० जनाले सहादत
              प्राप्त गरे । त्यस आन्दोलनमा यस गाउँपालिकाबाट समेत व्यापक
              जनसहभागिता जुटेको थियो । सात राजनीतिक दलको मार्गचित्र बमोजिम २०५९
              विघटन गरिएको संसदको पुर्नस्थापनसंगै ११ गतेको घोषणा पछि नेपालमा
              लोकतन्त्रको स्थापना भएको हो ।
            </p>
            <a href="#" class="see-more">
              थप जानकारी
              <unicon name="sign-out-alt"></unicon>
            </a>
          </div>
          <div v-if="isActive('naming')">
            <h2>मेल्लेखको नामकरण</h2>
            <p>
              पूर्व अवस्थामा उक्त क्षेत्रमा अन्न वालिको उत्पादन निकै कम हुने
              गर्दथ्यो । यहाँको मुख्य वाली जौ, आलु, मकै, कोदो तथा धान भएतापनि
              खाद्यान्नको अभाव हुने क्षेत्रमा वर्तमानको मेल्लेख गाँउपालिका पनि
              हो । तर घना तथा बाक्लो जंगलको यस क्षेत्रको विशेषता हो । बाक्लो
              जंगल भएतापनि बाक्लो कुकाठको बाहुल्यता अहिले पनि विद्यमान छ ।
              खासगरी १२०० मिटर भन्दा माथिल्लो भागमा हावापानी (न्यानो समशितोष्ण
              हावापानी (१२००– ३३०० मिटरसम्म) मा पनि विविधता हुने गर्दछ । यो
              हावापानी खासगरी कुकाठको लागि योग्य हुन्छ र यस प्रकारको हावापानी
              पाईने स्थानलाई लेक भनने गरिन्छ । यस उचाईमा रहेको भूभाग खासगरी
              मेलको बाहुल्यता हाल पनि उपलब्ध छ । लेकालि भूभाग र मेल सहितको
              लेकलाई संगै जोडी मेललेक भन्न थालियो । यसरी मेललेकबाट विकास हुदै
              हाल मेल्लेखको नमाबाट उक्त ठाउँ परिचित छ । संविधान कार्यान्वयन
              स्वरुप स्थानीय तहको निर्वाचन नेपालको राजनीतिक पार्टीहरुको पहिलो
              अनिवार्यता थियो । स्थानीय सरकार निर्माणका लागि २०७४ मा स्थानीय तह
              निर्वाचन सम्पन्न भयो । यस गाउँपालिकामा तत्कालिन माओवादी र
              कांग्रेसको गठबन्धन कायम गरियो । लोकबहादुर बोहरा अध्यक्ष (तत्कालिन
              माओवादी उम्मेदवार) र धनबहादुर शाही (कांग्रसेबाट) उपाध्यक्षमा विजयी
              प्राप्त गरे भने बहुमत वडाहरु तत्कालिन नेकपा (एमाले) विजयी हासिल
              ग¥यो । जसको फलस्वरुप स्थानीय सरकार निर्माण भई हाल क्रियासिल छ ।
              हाल लोकबहादुर बोहरा नेपाल कम्युनिष्ट पार्टी (नेकपा) को प्रदेश
              कमिटी सदस्य तथा उक्त पार्टीको निर्वाचित मोर्चा प्रमुख हुन ।
            </p>
            <a href="#" class="see-more">
              थप जानकारी
              <unicon name="sign-out-alt"></unicon>
            </a>
          </div>
        </div>
      </vue-custom-scrollbar> -->
    </div>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";

export default {
  components: {
    // eslint-disable-next-line
    vueCustomScrollbar,
  },
  computed: {
    checkAchievementLinkActive() {
      if (this.$route.name === "achievement-detail") return true;
      return false;
    },
    checkUpcomingLinkActive() {
      if (this.$route.name === "upcoming-program-detail") return true;
      return false;
    }
  },
  data() {
    return {
      key: 0,
      aboutInfo: {
        population: 0,
        wards: 0,
        families: 0,
        area: 0
      },
      activeItem: "achievements"
    };
  }
};
</script>

<style lang="scss" scoped>
.about-tab {
  background-color: $neutrals-midnight;
  box-shadow: 0px 25px 100px #191932;
  position: relative;
  
  &--inner {
    padding: 0 384px;

    @media screen and (max-width: 3199px) {
      padding: 0 190px;
    }

    @media all and (max-width: 1800px) {
      padding: 0 136px;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    cursor: pointer;
    padding: 100px 0;

    @media screen and (max-width: 3199px) {
      padding: 20px 0;
    }

    &:hover {
      color: $secondary;
    }

    &--active {
      border-bottom: 8px solid $secondary;

      @media screen and (max-width: 3199px) {
        border-width: 3px;
      }
    }
  }
}
</style>
